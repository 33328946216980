//
// Searchbar
//



.kt-searchbar {
    .input-group {
        .input-group-text {
            border: none;
            background-color: #f2f3f7; 
            padding: 0 0.65rem 0 1rem; 
            
            .kt-svg-icon {
                height: 20px;
                width: 20px;                
            }

            i {
                font-size: 1rem;
            }
        }

        .form-control {
            border: none;
            background-color: #f2f3f7; 
            padding: 0;           
        }     
    }

    .kt-input-icon {
        .form-control {
            border: none;
            background-color: #f2f3f7; 
        } 
        
        .kt-input-icon__icon {
            .kt-svg-icon {
                height: 20px;
                width: 20px;                
            }
        }
    }         
}

.kt-button-option{
    @mixin button-style {
        cursor:pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        margin-right: 2rem;
        background-color: kt-get($kt-state-colors-iniciatives, grey, base);
        color: kt-get($kt-state-colors, light, base);
        border-radius: 30rem;
        margin-bottom: 1rem;
        font-size: 12px;
        text-align: center;
    }

    .kt-container-option{
        @include button-style;
    }

    @mixin button-style-hover-clicked {
        @include button-style;
        color: kt-get($kt-state-colors, light, base);
        border: none;
    }

    .kt-container-option-blue{
        @include button-style;
    }

    .kt-container-option-click-blue{
        @include button-style-hover-clicked;
        background-color: kt-get($kt-state-colors-iniciatives, blue, base);
    }

    .kt-container-option-green{
        @include button-style;
    }

    .kt-container-option-click-green{
        @include button-style-hover-clicked;
        background-color: kt-get($kt-state-colors-iniciatives, green, base);
    }

    .kt-container-option-orange{
        @include button-style;
    }

    .kt-container-option-click-orange{
        @include button-style-hover-clicked;
        background-color: kt-get($kt-state-colors-iniciatives, orange, base);
    }

    .kt-container-option-brown{
        @include button-style;
    }

    .kt-container-option-click-brown{
        @include button-style-hover-clicked;
        background-color: kt-get($kt-state-colors-iniciatives, brown, base);
    }

    .kt-container-option-grey{
        @include button-style;
    }

    .kt-container-option-click-grey{
        @include button-style-hover-clicked;
        background-color: kt-get($kt-state-colors-iniciatives, grey, base);
    }
}   

