// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

body {
  display: block;
  height: 100%;
  font-family: "Catesque Regular";
}

h1, h2, h3, h4, h5 {
  font-family: "Biennale Bold";
}

p, input, button, strong {
  font-family: "Catesque Regular";
}
