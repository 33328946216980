.banner-content {
  margin-top: 150px;

  @media only screen and (max-width: 992px) {
    margin-top: 50px;
  }
}

.support-iniciative {
  padding: 30px 40px;

  .title {
    color: #5e49ff !important;
    text-transform: uppercase;
  }

  .content {
    margin-top: 20px;
    font-size: 16px;
    strong, .highlight {
      color: #5e49ff !important;
    }
  }
}


.join-iniciative {
  display: flex;
  flex-direction: row;
  margin: 20px 0;

  @media (max-width: 768px) {
    display: block;
  }

  div.iniciative {
    width: 50%;
    padding: 10px 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      padding: 20px 40px;
    }

    &.iniciative-right {
      display: flex;
      align-items: center;
      justify-content: center;

      .right-box {
        padding: 20px 15px;
        border-radius: 15px;

        background-color: #1c0c3f;
        color: black;

        @media (max-width: 768px) {
          width: 100%;
        }

        span.title {
          font-weight: bold;
          font-size: 20px;
        }

        p {
          font-size: 14px;
          margin: 0;
          margin-top: 15px;
        }
      }
    }

    &.iniciative-left {
      .left-title {
        font-size: 30px;

        p {
          letter-spacing: 1px;
          line-height: 32px;
          text-transform: uppercase;
          color: #5e49ff !important;
        }
      }

      .left-content {
        p {
          font-size: 18px;
          margin: 18px 0px;

          .highlight {
            color: #5e49ff !important;
          }
        }
      }

      .left-button {
        @media (min-width: 768px) {
          position: relative;
          top: 10px;
        }

        padding: 6px 15px;
        border-radius: 10px;
        border: 1px solid #5e49ff;

        text-transform: uppercase;

        color: white;
        background: #5e49ff;

        transition: all 0.1s linear;

        &:hover {
          top: 7px;
        }
      }
    }
  }
}
