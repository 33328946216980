@import "../../../_metronic/_assets/sass/config";


.option-user-profile{
    display: flex;
    align-items: center;
    color: kt-get($kt-state-colors, primary, base);

    cursor:pointer;
    &:hover{
        background-color: kt-get($kt-state-colors, colorProfileOption, base);
    }

    .icon-password{
        font-size: 1.8rem;
        color: kt-get($kt-state-colors, primary, base);
    }

}
