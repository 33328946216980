//
// Page - User Login 1
// Pages SASS files are compiled into separate css files
//

// Global config
@import "../../config";

// Layout config
@import "../../global/layout/config.scss";

.buttonWithHover {
  :hover {
    transition: 0.3s;
    opacity: 0.8;
    border: 1px solid kt-get($kt-state-colors, primary, base);
    color: kt-get($kt-state-colors, primary, base);
    background: transparent;
  }
}

// Login Base
.kt-login.kt-login--v1 {
  // Aside
  .kt-login__aside {
    width: 50%;
    padding: 5rem 3.5rem;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;

    .kt-login__logo {
      display: flex;
      img {
        width: 45%;
        @media (max-width: 1024px) {
          width: 35%;
        }
        @media (max-width: 768px) {
          width: 35%;
        }
        @media (max-width: 767px) {
          width: 35%;
        }
        @media (max-width: 375px) {
          width: 35%;
        }
      }
    }

    .kt-login__title {
      color: kt-get($kt-state-colors, primary, base);
      font-size: 2rem;
      font-weight: 500;
      font-family: kt-get($kt-font-families, regular);
    }

    .kt-login__subtitle {
      font-size: 1.2rem;
      font-weight: 200;
      margin: 2.5rem 0 3.5rem 0;
      color: rgba(#fff, 0.7);
      font-family: kt-get($kt-font-families, regular);
    }

    .kt-login__info {
      display: flex;
      justify-content: space-between;

      .kt-login__menu {
        > a {
          text-decoration: none;
          color: #fff;
          margin-right: 2rem;
          display: inline-block;

          @include kt-link-color(rgba(#fff, 0.7), #fff);

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .kt-login__copyright {
        color: rgba(#fff, 0.4);
      }
    }
  }

  .kt-login__link-no-account {
    cursor: pointer;
    font-weight: 400;
    @include kt-link-color(kt-base-color(label, 6), kt-base-color(label, 6));
  }

  .kt-login__terms {
    color: kt-get($kt-state-colors, primary, base);
  }

  // Wrapper
  .kt-login__wrapper {
    padding: 3rem 3rem;
    background: #fff;

    // Head
    .kt-login__head {
      font-size: 1rem;
      font-weight: 500;
      text-align: right;

      .kt-login__signup-label {
        color: kt-base-color(label, 2);
      }

      .kt-login__signup-link {
        color: kt-base-color(label, 6);
      }
    }

    // Body
    .kt-login__body {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      @media (max-width: 1024px) {
        justify-content: center;
      }

      // Form Wrapper
      .kt-login__form {
        width: 100%;
        max-width: 600px;
        border-left: 2px solid kt-get($kt-state-colors, hoverPrimary, opacity);
        padding: 70px 10px 0 10%;

        @media (max-width: 767px) {
          border-left: none;
          padding: 40px 20px;
        }
        @media (max-width: 1024px) {
          border-left: none;
          padding: 40px 20px;
        }

        .kt-login__title {
          display: block;
          text-align: center;
          margin-bottom: 5rem;
          @media (max-width: 767px) {
            margin-bottom: 0;
          }
          text-decoration: none;

          > h3 {
            font-size: 2rem;
            color: kt-get($kt-state-colors, primary, base);
          }
        }

        // Form
        .kt-form {
          margin: 4rem auto;
          @media (max-width: 767px) {
            margin: 1rem auto 0;
          }

          .form-group {
            margin: 0;
            padding: 0;
            margin: 0 auto;

            .form-control {
              border: none;
              height: 50px;
              margin-top: 1.25rem;
              background-color: rgba(#f7f7f9, 0.7);
              padding-left: 1.25rem;
              padding-right: 1.25rem;
              transition: background-color 0.3s ease;

              &:focus {
                transition: background-color 0.3s ease;
                background-color: #f7f7f9;
              }
            }
          }
        }
        // Action
        .kt-login__actions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .kt-login__link-forgot {
            text-decoration: underline;
            cursor: pointer;
            font-weight: 400;
            margin-bottom: 1rem;
            @include kt-link-color(kt-base-color(label, 6), kt-base-color(label, 6));
          }
          @mixin button-login {
            font-weight: 500;
            font-size: 1rem;
            height: 50px;
            padding-left: 2.75rem;
            padding-right: 2.75rem;
            margin: 3rem 0;
            border-color: kt-get($kt-state-colors, primary, base);
          }

          .kt-login__btn-primary {
            @include button-login();
            background-color: kt-get($kt-state-colors, primary, base);
            color: #ffff;
            margin: 3rem 0;
            font-family: "Poppins", sans-serif;
          }
          .kt-login__btn-secondary {
            @include button-login();
            color: kt-get($kt-state-colors, primary, base);
            margin: 3rem 0;
          }

          .kt-login__btn-google {
            background-color: kt-get($kt-social-colors, google, base);
            color: kt-get($kt-social-colors, google, inverse);
            width: 9rem;
          }

          .kt-login__btn-facebook {
            background-color: kt-get($kt-social-colors, facebook, base);
            color: kt-get($kt-social-colors, google, inverse);
            width: 9rem;
          }
        }

        // Divider
        .kt-login__divider {
          margin: 1rem 0 2rem 0;

          &:not(:first-child):not(:last-child) {
            font-weight: 400;
            color: kt-get($kt-state-colors, primary, base);
            font-size: 1rem;
          }
        }

        // Options
        .kt-login__options {
          display: flex;
          justify-content: center;
          justify-content: space-evenly;
          max-width: 100%;

          > a {
            text-decoration: none;
            flex: 1;
            justify-content: center;
            align-items: center;
            display: flex;

            @media (min-width: 1024px) {
              &:not(:last-child) {
                margin: 0 1.5rem 0 0;
              }
            }
          }
        }
      }
    }
  }

  @include kt-desktop {
    // Aside
    .kt-login__aside {
      flex: 1;
    }
  }

  @include kt-tablet-and-mobile() {
    // Aside
    .kt-login__aside {
      width: 100%;
      height: auto;
      padding: 2rem 1.5rem;

      .kt-login__info {
        margin-top: 2rem;
      }

      .kt-login__subtitle {
        margin: 2rem 0;
      }
    }

    // Wrapper
    .kt-login__wrapper {
      padding: 3rem 1.5rem;
      @media (max-width: 767px) {
        padding: 1rem 1.5rem;
      }

      .kt-login__head {
        padding-left: 2rem;
        right: 2rem;
      }

      .kt-login__body {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5rem;
        @media (max-width: 767px) {
          margin-top: 1rem;
        }

        .kt-login__form {
          .kt-login__options {
            > a {
              &:not(:first-child):not(:last-child) {
                margin: 0 0.8rem;
              }
            }
          }
        }
      }
    }
  }
}
