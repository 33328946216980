//
// Form
//

.kt-inputs-required {
	border-color: red;
}

.kt-error-label{
	color: kt-get($kt-state-colors, danger, base);
}
.kt-form {
	.kt-form__group {
		&.kt-form__group--inline {
			display: table;
			padding: 0;

			> .kt-form__label {
				display: table-cell;
				vertical-align: middle;
				margin: 0;
				padding-right: 1rem;

				&.kt-form__label-no-wrap {
					white-space:nowrap;
				}

				> label {
					margin: 0;
				}
			}

			> .kt-form__control {
				display: table-cell;
				vertical-align: middle;
				margin: 0;
				width: 100%;
			}
		}
	}

	// Action bar
	.kt-form__actions {
		&.kt-form__actions--right {
			text-align: right;
		}

		&.kt-form__actions--center {
			text-align: center;
		}
	}

	&.kt-form--center{
		width: 60%;
		margin: 0 auto;
	}
}

// Validation feedback
.is-valid {
	.valid-feedback {
		display: inline-block;
	}
}

.is-invalid {
	.invalid-feedback {
		display: inline-block;
	}
}

.container-iniciativas
{
		margin-bottom: 3rem;
		border-bottom-width: 1.8px;
		border-right-width: 1.8px;
		border-radius: 1rem;
		border-style: solid;
		border-color: kt-get($kt-state-colors, primary, base);

	.conteudo{
		margin-top: 2rem;
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.label-iniciativa{
	  margin-right: 0.3rem;
	  font-weight: bold;
	}

	// .text-iniciativa{
	// 	color: kt-get($kt-state-colors, dark, base);
	//   }
}

@include kt-desktop {
	.kt-form {
		&.kt-form--label-right {
			.form-group {
				label:not(.kt-checkbox):not(.kt-radio):not(.kt-option) {
					text-align: right;
				}
			}
		}
	}
}